import React from "react";

export default function ArrowRightIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 20"
      className={className}
      aria-hidden="true"
    >
      <path
        d="m15 0 10 10-10 10-1.75-1.75 7-7H0v-2.5h20.25l-7-7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
}
