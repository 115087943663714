import React from "react";
import Body from "components/Body";
import Button from "components/Button";
import MoreLink from "components/MoreLink";
import ArrowRightIcon from "components/SVG/Icon/ArrowRightIcon";
import Scribbles3 from "components/SVG/Scribble/Scribbles3";
import Link from "next/link";
import classNames from "lib/classNames";
import { m } from "framer-motion";
import { fadeIn, fadeUp } from "lib/variants";

export default function ExpertisesSection({ intro = null, terms }) {
  const parents = terms.filter((term) => term.parent[0].id === "virtual");

  return (
    <m.section variants={fadeIn} className="pt-16 2xl:pt-28">
      <div className="container px-4 lg:px-12 mx-auto">
        {intro && (
          <div className="grid grid-cols-1 2xl:grid-cols-2 justify-center gap-12 mb-12 2xl:mb-20">
            <m.div
              variants={fadeUp}
              initial="out"
              whileInView="enter"
              className="relative will-change-transform"
            >
              <span className="relative block mb-2.5 text-lg 2xl:text-xl font-bold z-10 will-change-transform">
                {intro.field_title_alt}
              </span>
              <div className="relative">
                <Scribbles3 className="absolute top-1/2 left-0 -translate-y-1/2 w-1/3 md:w-[13vw] text-primary" />
                <h2 className="relative text-4xl sm:text-5xl 2xl:text-6xl break-words sm:break-normal font-bold">
                  {intro.field_title}
                </h2>
              </div>
            </m.div>
            <m.div
              variants={fadeUp}
              initial="out"
              whileInView="enter"
              className="prose prose-dark 2xl:mt-5 will-change-transform"
            >
              <Body value={intro.body.processed} />
            </m.div>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {parents.map((term, index) => {
            const isFirst = index === 0;

            return (
              <m.div
                key={term.id}
                variants={fadeUp}
                initial="out"
                whileInView="enter"
                className={classNames(
                  "relative border-2 border-dark rounded-3xl p-4 lg:p-8 will-change-transform",
                  isFirst && "lg:col-span-2"
                )}
              >
                <h3 className="font-important tracking-wider font-semibold text-center lg:text-left text-2xl 2xl:text-3xl mb-8">
                  {term.name}
                </h3>

                {term.description && (
                  <div className="prose prose-dark mb-8">
                    <Body
                      value={
                        term.description.summary || term.description.processed
                      }
                    />
                  </div>
                )}

                <ul className="hidden lg:flex flex-wrap items-center gap-4">
                  {terms.map((subTerm) => {
                    return (
                      subTerm.parent[0].id === term.id && (
                        <li key={subTerm.id}>
                          <Link
                            href={subTerm.path.alias || "#"}
                            prefetch={false}
                            passHref
                            legacyBehavior
                          >
                            <Button as="a" theme="hover-dark" size="small">
                              {subTerm.name}
                            </Button>
                          </Link>
                        </li>
                      )
                    );
                  })}
                </ul>

                <div className="block lg:hidden text-right">
                  <Link
                    href={term.path.alias}
                    prefetch={false}
                    passHref
                    legacyBehavior
                  >
                    <a className="group inline-flex p-2 bg-transparent hover:bg-primary rounded-full transition-colors duration-300">
                      <span className="absolute inset-0" />
                      <ArrowRightIcon className="h-5 w-5 text-dark" />
                      <span className="sr-only">En savoir plus</span>
                    </a>
                  </Link>
                </div>
              </m.div>
            );
          })}
        </div>
        <m.div
          variants={fadeUp}
          initial="out"
          whileInView="enter"
          className="lg:text-right mt-12 will-change-transform"
        >
          <MoreLink href="/notre-expertise">
            <span className="hidden lg:inline">
              Découvrez tous nos domaines d’expertise
            </span>
            <span className="inline lg:hidden">Nos domaines d’expertise</span>
          </MoreLink>
        </m.div>
      </div>
    </m.section>
  );
}
